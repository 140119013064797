import Typography from 'typography'

const typography = new Typography({
	baseFontSize: '16px',
	baseLineHeight: 1.666,
	headerFontFamily: ['Open Sans', 'sans-serif'],
	bodyFontFamily: ['Open Sans', 'sans-serif'],
	// See below for the full list of options.
})

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
