import React from 'react'
import { Link, graphql } from 'gatsby'
import { rhythm, scale } from '../../utils/typography'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import 'bootstrap/dist/css/bootstrap.css'
import { Table } from 'reactstrap'

class PodCastTemplate extends React.Component {
  render() {
    const podcast = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    var items = []
    for (let i = 0; i < podcast.frontmatter.rating; i++) {
      items.push(i)
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={podcast.frontmatter.title} description={podcast.excerpt} />
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1>{podcast.frontmatter.title}</h1>
                <p
                  class="blog-post-date text-white text-center m-0"
                  style={{
                    ...scale(-1 / 5),
                    display: `block`,
                    marginBottom: rhythm(1),
                    marginTop: rhythm(-1),
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content podcast">
          <div className="container">
            <div className="col-md-8 m-0  text-center float-left pb-md-0 pb-4">
              <img
                className="img-fluid"
                src={podcast.frontmatter.banner}
                alt={podcast.frontmatter.title}
              />
            </div>
            <div
              className="text-center col-md-4"
              style={{
                display: `inline-grid`,
              }}
            >
              {/*<div className="text-left">
                <div
                  style={{
                    padding: `50px 0`,
                  }}
                  className="text-justify podcast-audio"
                  dangerouslySetInnerHTML={{ __html: podcast.html }}
                />
                <span>Title : </span>
                <h4>{podcast.frontmatter.title}</h4>
                <span>Title : </span>
                <audio
                  controls={true}
                  src={podcast.frontmatter.podcast_audio}
                  className="audio"
                ></audio>
                <span>Title : </span>
                <p>{podcast.frontmatter.author}</p>
                <span>Title : </span>
                <p>{podcast.frontmatter.date}</p>
              </div>*/}
              <Table>
                <tbody>
                  <tr>
                    <td className="f-14">Title :</td>
                    <td>{podcast.frontmatter.title}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Audio :</td>
                    <td>
                      <audio
                        controls={true}
                        src={podcast.frontmatter.podcast_audio}
                        className="audio"
                      >
                        <track
                          // src="fgsubtitles_no.vtt"
                          kind="captions"
                          srclang="eng"
                          label="English"
                        />
                      </audio>
                    </td>
                  </tr>
                  <tr>
                    <td className="f-14">Author :</td>
                    <td>{podcast.frontmatter.author}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Podcast :</td>
                    <td>{podcast.frontmatter.author}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Date Release :</td>
                    <td>{podcast.frontmatter.date}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Genre :</td>
                    <td>{podcast.frontmatter.genre}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Year :</td>
                    <td>{podcast.frontmatter.year}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Run Time :</td>
                    <td>{podcast.frontmatter.runtime}</td>
                  </tr>
                  <tr>
                    <td className="f-14">Rating :</td>

                    <td>
                      {items.map(rating => (
                        <i class="fa fa-star mr-2" />
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="f-14">
                      Comments <span>:</span>
                    </td>
                    <td>{podcast.frontmatter.comments}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />

            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link
                    to={previous.fields.slug}
                    rel="prev"
                    style={{ color: 'inherit' }}
                    title="Previous"
                  >
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link
                    to={next.fields.slug}
                    rel="next"
                    style={{ color: 'inherit' }}
                    title="Next"
                  >
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
            <div className="text-center pt-5 mt-4">
              <Link
                to="/podcast"
                className="btn btn-primary pink-transparent-btn btn-arrow"
                title="Back btn"
              >
                Back to the Podcast
              </Link>
            </div>
            <br />
            <br />
          </div>
        </div>
      </Layout>
    )
  }
}

export default PodCastTemplate

export const pageQuery = graphql`
  query PodCastBySlug($slug: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      fileAbsolutePath: { regex: "/podcast/" }
      fields: { slug: { eq: $slug } }
    ) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        author
        banner
        date(formatString: "MMMM DD, YYYY")
        podcast_audio
        genre
        year
        runtime
        rating
        comments
      }
    }
  }
`
